export const getInfoPagesTabs = (currentPage, intl) => [
  {
    text: intl.formatMessage({ id: 'General.aboutUs' }),
    selected: currentPage === 'AboutPage',
    id: 'AboutPageTab',
    linkProps: {
      name: 'AboutPage',
    },
  },
  {
    text: intl.formatMessage({ id: 'General.howItWorks' }),
    selected: currentPage === 'HowItWorksPage',
    id: 'HowItWorksPageTab',
    linkProps: {
      name: 'HowItWorksPage',
    },
  },
  {
    text: intl.formatMessage({ id: 'General.privacyPolicy' }),
    selected: currentPage === 'PrivacyPolicyPage',
    id: 'PrivacyPolicyPageTab',
    linkProps: {
      name: 'PrivacyPolicyPage',
    },
  },
  {
    text: intl.formatMessage({ id: 'General.termsOfService' }),
    selected: currentPage === 'TermsOfServicePage',
    id: 'TermsOfServicePageTab',
    linkProps: {
      name: 'TermsOfServicePage',
    },
  },
];
