import React from 'react';
import InfoPage from '../InfoPage';
import { useIntl } from 'react-intl';
import { ExternalLink } from '../../../components';
import { useConfiguration } from '../../../context/configurationContext';
import { LOCALE, getCurrentLocale } from '../../../util/locale';
import PrivacyPolicyBrazilContent from './PrivacyPolicyBrazilContent';

const LOCALE_TO_PRIVACY_POLICY__CONFIG_KEY = {
  [LOCALE.POLAND]: 'privacyPolicyUrlPL',
  [LOCALE.SOUTH_AFRICA]: 'privacyPolicyUrlZA',
  [LOCALE.UNITED_STATES]: 'privacyPolicyUrlUS',
};

// This "content-only" component can be used in modals etc.
const PrivacyPolicyContent = props => {
  const { className } = props;

  const config = useConfiguration();

  const locale = getCurrentLocale();

  const privacyURLContent = config[LOCALE_TO_PRIVACY_POLICY__CONFIG_KEY[locale]];

  return (
    <div className={className}>
      {privacyURLContent ? (
        <ExternalLink href={privacyURLContent}>{privacyURLContent}</ExternalLink>
      ) : (
        <PrivacyPolicyBrazilContent />
      )}
    </div>
  );
};

const PrivacyPolicyPage = () => {
  const intl = useIntl();
  const config = useConfiguration();

  return (
    <InfoPage
      title={intl.formatMessage({ id: 'General.privacyPolicy' })}
      contentTitle={intl.formatMessage(
        { id: 'PrivacyPolicyPage.contentTitle' },
        { marketplaceName: config.marketplaceName }
      )}
      currentPage="PrivacyPolicyPage"
    >
      <PrivacyPolicyContent />
    </InfoPage>
  );
};

export { PrivacyPolicyContent };

export default PrivacyPolicyPage;
