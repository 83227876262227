import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useConfiguration } from '../../context/configurationContext';
import StaticPage from '../PageBuilder/StaticPage';
import { H2, LayoutSideNavigation, TabNav } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import { getInfoPagesTabs } from './tabs';
import { node, string } from 'prop-types';

import css from './InfoPage.module.css';

const InfoPage = props => {
  const { className, title, children, currentPage, contentTitle, ...rest } = props;

  const intl = useIntl();
  const config = useConfiguration();

  const titleFinal = `${title} - ${config.marketplaceName}`;

  const tabs = useMemo(() => getInfoPagesTabs(currentPage, intl), [intl]);

  return (
    <StaticPage title={titleFinal} className={className}>
      <LayoutSideNavigation
        {...rest}
        topbar={<TopbarContainer />}
        footer={<FooterContainer />}
        sideNav={<TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />}
      >
        <H2 as="h1">{contentTitle}</H2>

        {children}
      </LayoutSideNavigation>
    </StaticPage>
  );
};

InfoPage.propTypes = {
  className: string,
  title: string.isRequired,
  currentPage: string.isRequired,
  contentTitle: node.isRequired,
};

export default InfoPage;
