import React from 'react';

const PrivacyPolicyBrazilContent = props => {
  return (
    <div>
      <p style={{ textAlign: 'right' }}>
        <u>
          <span style={{ backgroundColor: '#ffffff', fontSize: '9pt' }}>Data da publicação</span>
        </u>
        <span style={{ backgroundColor: '#ffffff', fontSize: '9pt' }}>: 01 de agosto de 2020</span>
      </p>
      <p />

      <p style={{ textAlign: 'justify' }}>
        <span style={{ backgroundColor: '#ffffff', fontSize: '10pt' }}>A&nbsp;</span>
        <strong>
          <span style={{ fontSize: '10pt' }}>Husqvarna&nbsp;</span>
        </strong>
        <span style={{ backgroundColor: '#ffffff', fontSize: '10pt' }}>
          leva muito a sério a privacidade dos Usuários, garantindo que qualquer operação de
          Tratamento durante&nbsp;
        </span>
        <span style={{ fontSize: '10pt' }}>o seu uso do site&nbsp;</span>
        <a href="http://www.husqvarna.com.br/">
          <u>
            <span style={{ color: '#0000ff', fontSize: '10pt' }}>www.husqvarna.com.br</span>
          </u>
        </a>
        <span style={{ fontSize: '10pt' }}>&nbsp;(&quot;Site&quot;),&nbsp;</span>
        <span style={{ backgroundColor: '#ffffff', fontSize: '10pt' }}>
          seja sempre realizada de acordo com a presente Política de Privacidade (“Política”),
          Termos de Uso e com a Legislação Aplicável, bem como a confidencialidade dos Dados
          Pessoais a que tem acesso.&nbsp;
        </span>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ backgroundColor: '#ffffff', fontSize: '10pt' }}>
          Quando esta Política menciona “Husqvarna”, “nós”, “nos” ou “nosso”, bem como as variações
          gramaticais da primeira pessoa do plural, ela se refere à empresa&nbsp;
        </span>
        <strong>
          <span style={{ fontSize: '10pt' }}>
            Husqvarna do Brasil Indústria e Comércio de Produtos para Floresta e Jardim Ltda.
          </span>
        </strong>
        <span style={{ fontSize: '10pt' }}>
          , sociedade empresária limitada, com endereço na Cidade de São Paulo, Estado de São Paulo,
          na Avenida Manuel Bandeira, nº 291, Bloco B, 1º andar, Vila Leopoldina, inscrita no
          CNPJ/MF sob n.º 04.098.470/0003-52,
        </span>
        <span style={{ backgroundColor: '#ffffff', fontSize: '10pt' }}>
          &nbsp;à Husqvarna AB (publ)/GARDENA GmbH ou às empresas do mesmo grupo, subsidiárias ou
          filiais.
        </span>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ backgroundColor: '#ffffff', fontSize: '10pt' }}>
          A Husqvarna agradece seu interesse&nbsp;
        </span>
        <span style={{ fontSize: '10pt' }}>por nós e por&nbsp;</span>
        <span style={{ backgroundColor: '#ffffff', fontSize: '10pt' }}>
          nossos produtos. Recomendamos que você (“Usuário” ou “Titular de Dados”) leia com atenção
          esta Política de Privacidade antes de navegar pel
        </span>
        <span style={{ fontSize: '10pt' }}>
          o nosso Site. Ao clicar em &quot;li e entendi&quot;, você (aqui também referido como
          “Usuário”) confirma haver lido e compreendido todas as condições desta Política.&nbsp;
        </span>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ backgroundColor: '#ffffff', fontSize: '10pt' }}>
          Nesta Política estão especificadas as finalidades para as quais a Husqvarna ou terceiros,
          em nome da Husqvarna, tratam os seus Dados Pessoais, os tipos de dados que são coletados e
          as medidas de proteção adotadas para a coleta, uso, armazenamento, segurança e
          confidencialidade dos seus Dados Pessoais.
        </span>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>
          <span style={{ backgroundColor: '#ffffff', fontSize: '10pt' }}>
            Esta Política de Privacidade está sujeita a alterações periódicas. Verifique-a
            regularmente.
          </span>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          As palavras constantes desta Política com letras maiúsculas terão o significado atribuído
          de acordo com estabelecido abaixo:
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <strong>
              <span style={{ fontSize: '10pt' }}>Autoridade Nacional de Proteção de Dados</span>
            </strong>
            <span style={{ fontSize: '10pt' }}>&nbsp;ou&nbsp;</span>
            <strong>
              <span style={{ fontSize: '10pt' }}>ANPD:</span>
            </strong>
            <span style={{ fontSize: '10pt' }}>
              &nbsp;significa a entidade independente da administração pública brasileira
              responsável por zelar, implementar e fiscalizar o cumprimento da LGPD em todo o
              território brasileiro.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10.5pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <strong>
              <span style={{ fontSize: '10.5pt' }}>Cookie:</span>
            </strong>
            <span style={{ fontSize: '10.5pt' }}>
              &nbsp;significa um pequeno arquivo que é colocado em seu dispositivo quando você
              visita um site (incluindo nossos Sites). Nesta Política, uma referência a um “Cookie”
              inclui tecnologias análogas, como web beacons e GIFs transparentes.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <strong>
              <span style={{ fontSize: '10pt' }}>Controlador:&nbsp;</span>
            </strong>
            <span style={{ fontSize: '10pt' }}>
              significa o Responsável pelo Tratamento de Dados Pessoais, a quem competem as decisões
              referentes ao Tratamento.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <strong>
              <span style={{ fontSize: '10pt' }}>Dados Anonimizados:&nbsp;</span>
            </strong>
            <span style={{ fontSize: '10pt' }}>
              significa qualquer dado relativo a um Titular que não possa ser identificado,
              considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião de seu
              Tratamento.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <strong>
              <span style={{ fontSize: '10pt' }}>Dados Pessoais:</span>
            </strong>
            <span style={{ fontSize: '10pt' }}>
              &nbsp;significa qualquer informação relacionada a uma pessoa natural identificada ou
              identificável, direta ou indiretamente, em particular por referência a um número de
              identificação ou a um ou mais fatores específicos à mesma, que seja divulgada,
              fornecida ou acessada pela Husqvarna para os fins de disponibilização e funcionamento
              do Site.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <strong>
              <span style={{ fontSize: '10pt' }}>Legislação Aplicável:&nbsp;</span>
            </strong>
            <span style={{ fontSize: '10pt' }}>
              significa, enquanto permanecer em vigor, a Lei Geral de Proteção de Dados Pessoais
              (Lei Nº 13.709/2018 ou “
            </span>
            <u>
              <span style={{ fontSize: '10pt' }}>LGPD</span>
            </u>
            <span style={{ fontSize: '10pt' }}>
              ”), e alterações posteriores, o Marco Civil da Internet (Lei Nº 12.965/2014 e Decreto
              Nº &nbsp;8.771/2016), a Constituição Federal, Código Civil Brasileiro, Código Penal,
              Código de Defesa do Consumidor (Lei Nº 8.078/1990 e Decreto Nº 7.963/2013), suas
              alterações posteriores, e quaisquer outras leis e regulamentos em relação ao
              Tratamento, privacidade, sigilo e proteção de Dados Pessoais que sejam aplicáveis e,
              se aplicáveis, todas as orientações, normas, regras, portarias, regulamentos e códigos
              de prática e conduta emitidos pela Autoridade Nacional de Proteção de Dados (“
            </span>
            <u>
              <span style={{ fontSize: '10pt' }}>ANPD</span>
            </u>
            <span style={{ fontSize: '10pt' }}>
              ”) ou outra autoridade de supervisão pertinente.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <strong>
              <span style={{ fontSize: '10pt' }}>Loja On-line Husqvarna:</span>
            </strong>
            <span style={{ fontSize: '10pt' }}>
              &nbsp;É o Site de internet registrado no Núcleo de Informação e Coordenação do Ponto
              BR (www.registro.com.br) com o domínio lojahusqvarna.com.br, para venda de produtos da
              linha Husqvarna.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <strong>
              <span style={{ fontSize: '10pt' }}>Operador:&nbsp;</span>
            </strong>
            <span style={{ fontSize: '10pt' }}>
              significa qualquer pessoa natural ou jurídica, de direito público ou privado, que
              realiza o Tratamento de Dados Pessoais em nome do Controlador de Dados.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <strong>
              <span style={{ fontSize: '10pt' }}>Site:</span>
            </strong>
            <span style={{ fontSize: '10pt' }}>
              &nbsp;Local na Internet identificado por um nome de domínio, constituído por uma ou
              mais páginas de hipertexto, que podem conter textos, gráficos e informações em
              multimídia,&nbsp;
            </span>
            <span style={{ fontSize: '10.5pt' }}>operado ou mantido por nós ou em nosso nome</span>
            <span style={{ fontSize: '10pt' }}>.</span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <strong>
              <span style={{ fontSize: '10pt' }}>Titular:</span>
            </strong>
            <span style={{ fontSize: '10pt' }}>
              &nbsp;significa a pessoa natural a quem se referem os Dados Pessoais que são objeto de
              Tratamento.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <strong>
              <span style={{ fontSize: '10pt' }}>Tratamento:&nbsp;</span>
            </strong>
            <span style={{ fontSize: '10pt' }}>
              significa qualquer operação ou conjunto de operações realizadas com Dados Pessoais ou
              conjuntos de Dados Pessoais, por meios automatizados ou não automatizados, tais como a
              coleta, acesso, produção, recepção, classificação, utilização, reprodução,
              transmissão, distribuição, processamento, arquivamento, registro, organização,
              estruturação, armazenamento, adaptação, alteração, recuperação, consulta, uso,
              divulgação por transmissão, comunicação, transferência, difusão, disponibilização,
              alinhamento, combinação, restrição, eliminação, avaliação ou controle da informação,
              modificação, apagamento, destruição ou extração. Tratamento e Tratado terão
              significado correspondente.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <strong>
              <span style={{ fontSize: '10pt' }}>Usuário:</span>
            </strong>
            <span style={{ fontSize: '10pt' }}>
              &nbsp;Pessoa física ou jurídica que acessa e compra os produtos vendidos pela Loja
              On-Line Husqvarna.
            </span>
          </p>
        </li>
      </ul>
      <p style={{ textAlign: 'justify', borderBottom: 'solid #b4b4b4 0.75pt' }}>
        <strong>
          <span style={{ fontSize: '12pt' }}>GERAL</span>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Observe que, para ter acesso a todas as funcionalidades do nosso Site, assim como
          participar de nossas atividades, ou para obter acesso a alguns de nossos produtos ou
          serviços, será necessário fornecer informações que o identifiquem, como endereço de
          e-mail, nome, número de telefone e CPF (&quot;Dados Pessoais&quot;) em algumas áreas do
          Site. Portanto, é importante que você compreenda plenamente de que forma os seus Dados
          Pessoais são coletados, utilizados, armazenados e transferidos.
        </span>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          A presente Política será regida e interpretada segundo a legislação brasileira, no idioma
          português, sendo eleito o Foro da Comarca de São Paulo para dirimir qualquer litígio ou
          controvérsia envolvendo o presente documento, salvo ressalva específica de competência
          pessoal, territorial ou funcional pela legislação aplicável.
        </span>
      </p>
      <p style={{ textAlign: 'justify', borderBottom: 'solid #b4b4b4 0.75pt' }}>
        <strong>
          <span style={{ fontSize: '12pt' }}>COLETA E USO DE DADOS PESSOAIS&nbsp;</span>
        </strong>
      </p>
      <div>
        <table style={{ border: 'none', borderCollapse: 'collapse' }}>
          <tbody>
            <tr>
              <td
                style={{
                  color: '#273a60',
                  backgroundColor: '#273a60',
                  border: 'solid #000000 0.75pt',
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <strong>
                    <span style={{ color: '#ffffff', fontSize: '10pt' }}>RESUMO</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  backgroundColor: '#e2e3e6',
                  border: 'solid #000000 0.75pt',
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '9pt' }}>
                    Realizamos o tratamento dos seus dados de identificação (por exemplo, seu nome,
                    sua faixa etária ou gênero); suas informações de contato (por exemplo, seu
                    e-mail, endereço ou telefone); registros de seus consentimentos; informações
                    sobre a navegação em nosso Site (por exemplo, o tipo de dispositivo que você
                    está usando); seus dados analíticos (por exemplo dados de geolocalização)
                    informações sobre suas interações com nosso conteúdo ou publicidade; e quaisquer
                    considerações ou opiniões que você nos fornecer ao interagir conosco em nosso
                    Site, Loja On-Line Husqvarna ou Redes Sociais.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10.5pt' }}>
          <br />
        </span>
        <span style={{ fontSize: '10pt' }}>
          Os Dados Pessoais são armazenados e gerenciados pela Husqvarna, ou seja, somos o
          Controlador de seus Dados Pessoais e utilizamos as suas informações para melhorar a sua
          navegação em nosso Site, bem como para entrar em contato com você e dar prosseguimento à
          sua solicitação, caso aplicável.&nbsp;
        </span>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Coletamos ou obtemos seus Dados Pessoais das seguintes formas:
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <u>
              <span style={{ fontSize: '10pt' }}>Dados fornecidos a nós:</span>
            </u>
            <span style={{ fontSize: '10pt' }}>
              &nbsp;Quando esses dados são fornecidos a nós por exemplo, quando você enviar
              formulários da Web, se cadastra, ou entra em contato conosco por exemplo solicitando
              suporte, enviando críticas, elogios ou sugestões em nossos canais por meio do nosso
              SAC por e-mail ou telefone, via Chat ou formulário Fale Conosco, entra em um
              concurso/sorteio, ou participa de uma campanha promocional.
            </span>
          </p>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <u>
              <span style={{ fontSize: '10pt' }}>Dados que você torna públicos:</span>
            </u>
            <span style={{ fontSize: '10pt' }}>
              &nbsp;Dados Pessoais que você espontaneamente opta por tornar públicos, inclusive
              através das redes sociais (por exemplo, podemos coletar informações de seu(s)
              perfil(s) nas redes sociais, caso você faça uma postagem pública sobre nós ou interage
              conosco em nossas redes sociais).
            </span>
          </p>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <u>
              <span style={{ fontSize: '10pt' }}>Dados de rastreamento:</span>
            </u>
            <span style={{ fontSize: '10pt' }}>
              &nbsp;Podemos coletar ou obter Dados Pessoais através do rastreamento de páginas da
              web e de newsletters por meio de Cookies.
            </span>
            <span style={{ fontSize: '12pt' }}>&nbsp;</span>
            <span style={{ fontSize: '10pt' }}>
              Para entender melhor como funciona a coleta de Dados de Navegação por meio de Cookies,
              viste a Seção “Cookies” contida nesta Política.
            </span>
          </p>
        </li>
      </ul>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10.5pt' }}>
          Podemos realizar o Tratamento das seguintes categorias de Dados Pessoais sobre você, desde
          que tenhamos uma base legal aplicável:
        </span>
      </p>
      <ul>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <u>
              <span style={{ fontSize: '10pt' }}>Dados de identificação:</span>
            </u>
            <span style={{ fontSize: '10pt' }}>&nbsp;nome, sobrenome, faixa etária e gênero.</span>
          </p>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <u>
              <span style={{ fontSize: '10pt' }}>Dados de contato:</span>
            </u>
            <span style={{ fontSize: '10pt' }}>&nbsp;e-mail, telefone e endereço.</span>
          </p>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <u>
              <span style={{ fontSize: '10pt' }}>Registros de consentimento:</span>
            </u>
            <span style={{ fontSize: '10pt' }}>
              &nbsp;registros de quaisquer consentimentos que você nos deu, juntamente com a data e
              hora, os meios de consentimento e qualquer informação relacionada (por exemplo, o
              assunto do consentimento).
            </span>
          </p>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <u>
              <span style={{ fontSize: '10pt' }}>
                Dados analíticos ou relacionados uso dos&nbsp;
              </span>
            </u>
            <span style={{ fontSize: '10.5pt' }}>nossos Sites ou&nbsp;</span>
            <span style={{ fontSize: '10pt' }}>Loja On-Line Husqvarna</span>
            <u>
              <span style={{ fontSize: '10pt' }}>:</span>
            </u>
            <span style={{ fontSize: '10pt' }}>
              &nbsp;tipo de dispositivo, sistema operacional, tipo de navegador, configurações do
              navegador, endereço de IP, opções de idioma, datas e horas de conexão a um Site, dados
              de localização e outras informações de comunicações técnicas (algumas das quais podem
              constituir Dados Pessoais), nome do usuário, senha, detalhes de login de segurança,
              dados de uso, e informações estatísticas combinadas. Essas informações são coletadas
              cumulativamente e são utilizadas para proporcionar uma experiência melhor nas suas
              interações com a Husqvarna. Os Dados de Navegação, como o endereço IP, são utilizados
              para obter a sua localização aproximada e assim conseguir por exemplo lhe mostrar
              quais são os revendedores mais perto de você ou ainda armazenar o seu carrinho de
              compras. A Husqvarna pode também, coletar estes dados com base nos nossos interesses
              legítimos, ao passo que buscamos aprimorar nossa Plataforma e nossos Serviços, a fim
              de garantir que o conteúdo seja apresentado da maneira mais eficaz para você e para o
              seu dispositivo.
            </span>
          </p>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <u>
              <span style={{ fontSize: '10pt' }}>Dados do conteúdo e publicidade:</span>
            </u>
            <span style={{ fontSize: '10pt' }}>
              &nbsp;registros de suas interações com nossas publicidades e conteúdos online,
              registros de publicidade e conteúdos exibidos em páginas ou telas de Site exibidas a
              você e qualquer interação que você possa ter tido com tal conteúdo ou publicidade (por
              exemplo, passar o mouse, cliques com o mouse, quaisquer formulários que você preencher
              de maneira completa ou parcial) e quaisquer interações através de tecnologia
              &nbsp;touchscreen.
            </span>
          </p>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <u>
              <span style={{ fontSize: '10pt' }}>Comentários e opiniões:</span>
            </u>
            <span style={{ fontSize: '10pt' }}>
              &nbsp;quaisquer comentários e opiniões que você optar por nos enviar ou postar
              publicamente sobre nós&nbsp;
            </span>
            <span style={{ fontSize: '10.5pt' }}>em nosso Site,&nbsp;</span>
            <span style={{ fontSize: '10pt' }}>Loja On-Line Husqvarna ou Redes Sociais.</span>
          </p>
        </li>
      </ul>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Caso você opte por nos contactar via &nbsp;nosso canal de SAC coletaremos também o áudio
          decorrente da nossa ligação telefônica. Tal Tratamento será decorrente do nosso contrato
          com você ou com base nas nossas obrigações legais e regulatórias e para o exercício
          regular de direitos de defesa em processo judicial, administrativo ou arbitral.
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Ainda, no momento da sua inscrição ou do seu cadastro a Husqvarna poderá solicitar o seu
          consentimento prévio, via&nbsp;
        </span>
        <em>
          <span style={{ fontSize: '10pt' }}>check box,</span>
        </em>
        <span style={{ fontSize: '10pt' }}>
          &nbsp;para enviar informações adicionais não obrigatórias com o objetivo de realizar
          marketing direto, enviar&nbsp;
        </span>
        <em>
          <span style={{ fontSize: '10pt' }}>newsletters&nbsp;</span>
        </em>
        <span style={{ fontSize: '10pt' }}>
          ou de convidá-lo a participar de algum de nossos eventos, como feiras e congressos,
          conforme a seção “Marketing Direto” abaixo.
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Cumpre ressaltar que, a Husqvarna não reúne intencionalmente nenhum Dado Pessoal de
          menores de 18 anos de idade, tampouco o nosso Site é direcionada a menores de 18
          anos.&nbsp;
        </span>
        <span style={{ fontSize: '10.5pt' }}>
          Aconselhamos os pais e responsáveis a se interessarem ativamente pelas atividades e
          interesses das crianças nesse universo online.
        </span>
      </p>
      <p style={{ textAlign: 'justify', borderBottom: 'solid #b4b4b4 0.75pt' }}>
        <strong>
          <span style={{ fontSize: '12pt' }}>MARKETING DIRETO</span>
        </strong>
      </p>
      <div>
        <table style={{ border: 'none', borderCollapse: 'collapse' }}>
          <tbody>
            <tr>
              <td
                style={{
                  color: '#273a60',
                  backgroundColor: '#273a60',
                  border: 'solid #000000 0.75pt',
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <strong>
                    <span style={{ color: '#ffffff', fontSize: '10pt' }}>RESUMO</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  backgroundColor: '#e2e3e6',
                  border: 'solid #000000 0.75pt',
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '9pt' }}>
                    Tratamos Dados Pessoais para contatá-lo com informações sobre produtos ou
                    serviços que podem ser do seu interesse. Você pode cancelar a assinatura
                    gratuitamente a qualquer momento.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Tratamos Dados Pessoais para entrar em contato com você por e-mail, telefone, mala direta
          ou outros formatos de comunicação para fornecer informações sobre produtos ou serviços que
          possam ser do seu interesse.&nbsp;
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Se nós já temos uma relação pré-existente, pois disponibilizamos produtos ou serviços para
          você, ou se você solicitou ativamente o recebimento de nossas newsletters, podemos enviar
          informações a você sobre nossos Sites, Aplicativos, produtos ou serviços, futuras
          promoções e outras informações que possam ser de seu interesse, usando as informações de
          contato que você nos forneceu, sujeito sempre à obtenção de seu consentimento prévio na
          medida exigida pela Legislação Aplicável.
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Nossas newsletters contêm pixels de rastreamento. Um pixel de rastreamento é um gráfico
          invisível em e-mails HTML; sua finalidade é gerar um arquivo de log quando o e-mail for
          aberto e registrar quais links são ativados a partir da newsletter e posteriormente
          analisar esses dados. Isso nos permite, por meio de avaliações estatísticas, determinar o
          sucesso das nossas campanhas de newsletter e otimizar as nossas newsletters, por exemplo,
          para que receba informações e ofertas mais adequadas aos seus interesses.
        </span>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Você pode cancelar a assinatura de nossa lista de e-mail promocional a qualquer momento,
          simplesmente clicando no link de cancelamento incluído em cada e-mail promocional que
          enviamos. Depois de cancelar a assinatura, não enviaremos mais e-mails promocionais, mas,
          em algumas circunstâncias, continuaremos a contatá-lo na medida necessária para os
          propósitos de quaisquer Sites, Aplicativos, produtos ou serviços que você tenha
          solicitado.
        </span>
      </p>
      <p style={{ textAlign: 'justify', borderBottom: 'solid #b4b4b4 0.75pt' }}>
        <strong>
          <span style={{ fontSize: '12pt' }}>TEMPO DE TRATAMENTO</span>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Somente reteremos os seus Dados Pessoais pelo tempo necessário para cumprir a finalidade
          para a qual foram coletados. Tomamos todas as medidas razoáveis para garantir que seus
          Dados Pessoais sejam retidos apenas pelo tempo que for necessário para seu propósito
          específico. O período de retenção para cada categoria de Dados Pessoais é baseado nos
          requisitos legais aplicáveis e no objetivo para o qual as informações são coletadas e
          utilizadas.
        </span>
      </p>
      <p style={{ textAlign: 'justify', borderBottom: 'solid #b4b4b4 0.75pt' }}>
        <strong>
          <span style={{ fontSize: '12pt' }}>COMPARTILHAMENTO DE DADOS PESSOAIS</span>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          A Husqvarna não compartilhará os Dados Pessoais fornecidos por você no Site com nenhum
          terceiro, exceto (a) mediante o seu consentimento; (b) por determinação legal ou ordem
          judicial; (c) para proteger os direitos ou a propriedade da Husqvarna; (d) para proteger a
          segurança pessoal dos Usuários do Site; (e) para pessoas ou empresas com as quais a
          Husqvarna tenha contatos para executar as operações internas do Site ou nossos negócios;
          ou (f) se considerarmos, justificadamente, que estejam sujeitos a lei, regime vinculativo
          ou contrato que apoie os princípios de manejo justo e o nível de proteção adequado dos
          Dados Pessoais.&nbsp;
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Sob o seu critério, a Husqvarna se reserva o direito de entrar em contato com a autoridade
          apropriada caso perceba a ocorrência de atividades possivelmente ilegais ou que violem a
          presente Política de Privacidade ou os Termos de Uso da Husqvarna.
        </span>
      </p>
      <p style={{ textAlign: 'justify', borderBottom: 'solid #b4b4b4 0.75pt' }}>
        <strong>
          <span style={{ fontSize: '12pt' }}>COOKIES</span>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          O nosso Site, como a maioria dos websites, utilizar uma tecnologia de navegação chamada
          “Cookies”.&nbsp;
        </span>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Os Cookies são pequenos arquivos de texto com informações que serão guardadas no seu
          computador, sobre a página que você visitou. Eles são usados para ajudar a fornecer
          funcionalidades adicionais ao nosso Site e para nos ajudar a analisar a forma como você
          navega. Existem dois tipos de cookies: Um é usado para &quot;lembrar&quot; informações
          como seu nome, senha, últimos itens buscados e é armazenado no seu computador durante o
          tempo que você decidir. Se utilizar a função &quot;não armazenar usuário e senhas para o
          futuro&quot; os Cookies nunca serão armazenados.
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Outro &quot;Cookie&quot; conhecido é o chamado cookie de sessão. É usado para manter o
          controle sobre qual o idioma que você escolheu para a página que visitou. Durante o tempo
          da sua visita esses Cookies são armazenados temporariamente na memória do seu computador.
          Ao usar esses cookies e visando os nossos interesses legítimos, a Husqvarna espera
          direcionar melhor as informações relevantes para os nossos Usuários.&nbsp;
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Se você não quiser aceitar o uso de cookies pela Husqvarna, configure no painel do seu
          navegador essa rejeição. No entanto, isso pode limitar as funcionalidades e os benefícios
          da navegação em nosso Site.
        </span>
      </p>
      <p style={{ textAlign: 'justify', borderBottom: 'solid #b4b4b4 0.75pt' }}>
        <strong>
          <span style={{ fontSize: '12pt' }}>DIREITOS DO TITULAR DE DADOS</span>
        </strong>
      </p>
      <div>
        <table style={{ border: 'none', borderCollapse: 'collapse' }}>
          <tbody>
            <tr>
              <td
                style={{
                  color: '#273a60',
                  backgroundColor: '#273a60',
                  border: 'solid #000000 0.75pt',
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <strong>
                    <span style={{ color: '#ffffff', fontSize: '10pt' }}>RESUMO</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  backgroundColor: '#e2e3e6',
                  border: 'solid #000000 0.75pt',
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '9pt' }}>
                    Você tem vários direitos com relação aos seus Dados Pessoais, incluindo: o
                    direito de acesso; o direito de obter a confirmação da existência de Tratamento;
                    o direito de solicitar a correção de seus Dados Pessoais; o direito de solicitar
                    a eliminação de Dados Pessoais quando o tratamento for baseado no consentimento;
                    o direito de solicitar a anonimização, o bloqueio ou a eliminação de Dados
                    Pessoais desnecessários, excessivos ou não-conformes com as previsões da LGPD; o
                    direito de ter seus Dados Pessoais transferidos para outro Controlador; e o
                    direito de apresentar queixas à ANPD. Em alguns casos, será necessário fornecer
                    evidências de sua identidade antes de podermos garantir o exercício destes
                    direitos.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10.5pt' }}>
          <br />
        </span>
        <span style={{ fontSize: '10pt' }}>
          Você possui os seguintes direitos em relação ao Tratamento de seus Dados Pessoais por nós,
          a qualquer momento, e mediante requisição:
        </span>
      </p>

      <ul>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              Direito a obter o acesso aos seus Dados Pessoais objeto de Tratamento;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              Direito a obter a confirmação da existência de Tratamento;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              Direito de solicitar a correção de Dados Pessoais incorretos, desatualizados e/ou
              incompletos;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              Direito de solicitar a eliminação de Dados Pessoais quando o Tratamento for baseado no
              consentimento;&nbsp;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              Direito de se opor ao Tratamento de Dados Pessoais realizado com base em uma das
              hipóteses de dispensa de consentimento, em caso de não cumprimento das disposições da
              LGPD;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              Direito de solicitar a anonimização, o bloqueio ou a eliminação de Dados Pessoais
              desnecessários, excessivos ou não-conformes com as previsões da LGPD;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              Direito à portabilidade dos Dados Pessoais, ou seja, à transmissão dos dados a outro
              Controlador, desde que tal transmissão seja baseada no consentimento do indivíduo ou
              seja necessária para executar uma relação contratual, de acordo com os regulamentos da
              ANPD, e sujeita a segredos comerciais e industriais;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              Direito de retirar o consentimento quando o Tratamento for baseado no consentimento;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              Direito de receber informações sobre o não fornecimento do consentimento e as
              consequências desta negativa;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              Direito de receber informações sobre as entidades públicas e privadas com as quais o
              Controlador realizou o uso compartilhado de Dados Pessoais;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              Direito à revisão de uma decisão automatizada, ou seja, decisões tomadas
              exclusivamente com base no tratamento automatizado de Dados Pessoais que afetam os
              seus interesses; e
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              Direito a peticionar à ANPD contra as práticas de proteção de dados e privacidade.
            </span>
          </p>
        </li>
      </ul>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Caso tenhamos coletado e tratado seus Dados Pessoais e a base legal tenha sido o
          consentimento, você poderá revogá-lo a qualquer momento. A anulação do seu consentimento
          não afetará a legalidade de nenhum tratamento que conduzimos antes da anulação, nem
          afetará o Tratamento dos seus Dados Pessoais conduzidos de acordo com o Tratamento lícito
          independentemente do seu consentimento.
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Para exercer um ou mais desses direitos, ou fazer uma pergunta sobre esses direitos ou
          sobre o nosso Tratamento de seus Dados Pessoais, contate-nos através do e-mail:&nbsp;
        </span>
        <a href="mailto:privacidade@husqvarnagroup.com">
          <u>
            <span style={{ color: '#0000ff', fontSize: '10pt' }}>
              privacidade@husqvarnagroup.com
            </span>
          </u>
        </a>
        <span style={{ fontSize: '10pt' }}>.</span>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>Observe que:</span>
      </p>
      <ul>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              em alguns casos, será necessário apresentar evidências quanto à sua identidade antes
              de podermos garantir o exercício desses direitos; e
            </span>
          </p>
        </li>
        <li style={{ listStyleType: 'disc', fontSize: '10pt' }}>
          <p style={{ textAlign: 'justify' }}>
            <span style={{ fontSize: '10pt' }}>
              quando sua solicitação exigir o conhecimento de fatos adicionais (por exemplo, a
              determinação de se algum Tratamento não está em conformidade com a lei aplicável),
              investigaremos sua solicitação com razoável rapidez, antes de decidir que ação tomar.
            </span>
          </p>
        </li>
      </ul>
      <p style={{ textAlign: 'justify', borderBottom: 'solid #b4b4b4 0.75pt' }}>
        <strong>
          <span style={{ fontSize: '12pt' }}>SEGURANÇA DA INFORMAÇÃO</span>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          As informações serão armazenadas em um ambiente seguro e protegido, com o objetivo de
          fornecer aos nossos Usuários um ambiente que ele se sinta seguro. Adotamos meios técnicos
          e procedimentos de segurança apropriados para proteger seus Dados Pessoais contra o uso
          indevido, acesso não autorizado, interferência, alteração, divulgação ou perda, por meio
          de medidas como o uso de criptografia, limitação do acesso digital e físico às nossas
          instalações, limitação do acesso às informações coletadas, dentre outras medidas, de modo
          a cumprir com suas obrigações quanto à confidencialidade e inviolabilidade dos seus Dados.
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Como a Internet é um sistema aberto, a transmissão de informações pela Internet não é
          completamente segura. Embora venhamos a implementar todas as medidas razoáveis para
          proteger os seus Dados Pessoais, não podemos garantir a segurança dos seus dados
          transmitidos para nós através da Internet - toda referida transmissão é por sua conta e
          risco e você é responsável por garantir que quaisquer Dados Pessoais que enviar para nós
          sejam enviados com segurança.
        </span>
      </p>
      <p style={{ textAlign: 'justify', borderBottom: 'solid #b4b4b4 0.75pt' }}>
        <strong>
          <span style={{ fontSize: '12pt' }}>OUTROS WEBSITES</span>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          O nosso Site pode conter links para websites de terceiros. A Husqvarna não exerce controle
          nem é responsável pelos conteúdos ou práticas de privacidade de websites de terceiros que
          venham a ter links no Site. Tais websites podem colocar os seus próprios cookies no seu
          computador e/ou têm diferentes meios de coleta e de manuseio dos seus Dados Pessoais.
          Recomendamos que você se familiarize com as práticas de privacidade desses websites de
          terceiros antes de utilizá-los.
        </span>
      </p>
      <p style={{ textAlign: 'justify', borderBottom: 'solid #b4b4b4 0.75pt' }}>
        <strong>
          <span style={{ fontSize: '12pt' }}>ALTERAÇÕES&nbsp;</span>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Esta Política de Privacidade entra em vigor a partir da data da sua publicação,
          estabelecida acima.
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          A Husqvarna reserva-se o direito de a seu critério exclusivo alterar, modificar,
          acrescentar ou eliminar qualquer parte ou a totalidade da presente Política de
          Privacidade, a qualquer momento conforme a finalidade ou necessidade, tal qual para
          adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica
          equivalente, sempre de acordo com a Legislação Aplicável, cabendo a você verificá-la
          sempre que visitar os nossos Sites. Se fizermos alterações nesta Política de Privacidade,
          nós o notificaremos através de um aviso em nossos Sites.
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Em qualquer caso, as alterações efetuadas a Política de Privacidade entrarão em vigor na
          data de sua publicação. A navegação continuada do Site após a publicação de qualquer
          alteração à Política de Privacidade será considerada como uma indicação de entendimento e
          leitura de tais alterações.
        </span>
      </p>
      <p style={{ textAlign: 'justify', borderBottom: 'solid #b4b4b4 0.75pt' }}>
        <strong>
          <span style={{ fontSize: '12pt' }}>CONTATO&nbsp;</span>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Perguntas, comentários e pedidos relativos a esta Política de Privacidade são bem-vindos.
        </span>
      </p>

      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10pt' }}>
          Você pode entrar em contato com a equipe de gestão de Serviços da Husqvarna enviando um
          e-mail para&nbsp;
        </span>
        <a href="mailto:privacidade@husqvarnagroup.com">
          <u>
            <span style={{ color: '#0000ff', fontSize: '10pt' }}>
              privacidade@husqvarnagroup.com
            </span>
          </u>
        </a>
        <span style={{ fontSize: '10pt' }}>.</span>
      </p>
    </div>
  );
};

export default PrivacyPolicyBrazilContent;
